<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('requForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="requForm" :model="requForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="委托单号" prop="requ_no">
            <el-input
              maxlength="10"
              v-model="requForm.requ_no"
              @input="requForm.requ_no = helper.keepEngNum2(requForm.requ_no)"
              placeholder="请填写委托单号"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="委托人" prop="requ_stff_name">
            <el-select
              filterable
              v-model="requForm.requ_stff_name"
              @change="requStffChange"
              placeholder="请选择委托人"
              size="small"
              clearable
            >
              <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="要求交样时间" prop="requ_deli_time">
            <el-date-picker v-model="requForm.requ_deli_time" type="date" placeholder="请选择时间日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="客户名称" prop="cust_name">
            <el-input disabled v-model="requForm.cust_name" show-word-limit placeholder="请选择客户名称" @click.native="custImport()">
              <template slot="append">
                <el-link type="primary">选择</el-link>
              </template>
              {{ requForm.cust_name }}
            </el-input>
          </el-form-item>
          <el-dialog :title="CustTitle" :visible.sync="custFlag" width="70%">
            <requEditCust @custID="custID" @cancelCust="cancelCust"></requEditCust>
            <!-- <requEditCust @custID="custID" @custName="custName" @custMail="custMail" @custFcpFlag="custFcpFlag" @custPID="custPID" @custAbbr="custAbbr" @custCountry="custCountry"  @custCancel="custCancel" ></requEditCust> -->
          </el-dialog>
        </el-col>
        <el-col :md="8">
          <el-form-item label="打样接收人" prop="requ_receive">
            <el-select filterable v-model="requForm.requ_receive" placeholder="请选择打样接收人" size="small" clearable>
              <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户国家">
            <el-input v-model="contryName" placeholder="请先选择客户" show-word-limit disabled> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item
            label="打样部门"
            prop="belo_dept_id"
            :rules="requForm.smpl_list.length > 0 ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-select @change="beloChange" v-model="requForm.belo_dept_id" placeholder="请选择打样部门" clearable>
              <el-option v-for="item in beloType" :key="item.param2" :label="item.param1" :value="item.param2"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="委托备注" prop="requ_remark">
            <el-input
              type="textarea"
              rows="1"
              v-model="requForm.requ_remark"
              maxlength="200"
              show-word-limit
              placeholder="请填写委托备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_solidDiv"></div>
      <div>
        <div class="vd_but">
          <el-button type="primary" plain size="small" @click="addRow()">新增</el-button>
          <el-button type="success" plain size="small" @click="smplImport()">导入</el-button>
          <el-button type="danger" plain size="small" @click="delBom()">删除</el-button>
        </div>
        <RequSmplList ref="RequSmplList" :requForm="requForm" @handleSelectionChange="selectionChange"></RequSmplList>
      </div>
      <el-dialog :title="title" :visible.sync="smplFlag" width="70%">
        <RequSmplResult @falseSmpl="falseSmpl" @childSmpl="childSmpl" @childSmplChanel="childSmplChanel" ref="SmplEditIn"></RequSmplResult>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { requAPI } from '@api/modules/requ';
import { optnAPI } from '@api/modules/optn';
import { userAPI } from '@api/modules/user';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import RequSmplList from './Componet/RequSmplList.vue';
import RequSmplResult from './Componet/RequSmplResult.vue';
import requEditCust from './Componet/RequEditCust.vue';

export default {
  name: 'RequAddMain',
  components: {
    addHeader,
    inputUser,
    RequSmplList,
    RequSmplResult,
    requEditCust
  },
  data() {
    return {
      rules: {
        requ_no: [{ required: true, trigger: 'blur', message: ' ' }],
        requ_stff_name: [{ required: true, trigger: 'change', message: ' ' }],
        requ_deli_time: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      requForm: {
        requ_no: null,
        requ_receive: null,
        requ_stff_id: null,
        requ_stff_name: null,
        requ_stff_ename: null,
        requ_deli_time: null,
        requ_remark: null,
        cust_id: null,
        smpl_list: [],
        belo_dept_id: null,
        cust_name: null,
        cust_mail: null,
        cust_pid: null,
        cust_fcp_flag: null,
        cust_abbr: null,
        cust_country: null
      },
      stffType: [],
      selectChangeList: [],
      smplFlag: false,
      title: '样品总控表',
      custList: [],
      contryName: null,
      beloType: [],
      custFlag: false,
      CustTitle: '选择客户'
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  mounted() {
    let syncData = this.$route.params.syncData;
    if (syncData) this.requForm = syncData;
  },
  methods: {
    initData() {
      this.getStffType();
      // this.getCustList()
      this.getContry();
    },
    //提交表单
    submit(formName) {
      this.$refs.RequSmplList.smplFlag = 0;
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(val => {
          setTimeout(() => {
            this.$refs[formName].validate(valid => {
              if (valid) {
                this.saveInfo();
              } else {
                this.$message.warning('请把必填内容补充完整');
                console.log('error submit!');
                return false;
              }
            });
          }, 300);
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let requForm = {};
      requForm = Object.assign(requForm, this.requForm, staffForm);
      requForm.requ_deli_time = new Date(requForm.requ_deli_time).getTime() / 1000;
      for (let i = 0; i < requForm.smpl_list.length; i++) {
        requForm.smpl_list[i].smpl_deil_time = parseInt(requForm.smpl_list[i].smpl_deil_time / 1000);
      }
      requForm.cust_country = this.contryName;
      if (requForm.smpl_list.length === 0) {
        if (!requForm.belo_dept_id) {
          requForm.belo_dept_id = 0;
        }
      }
      post(requAPI.addRequ, requForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump(`/requ_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`);
            this.resetForm('requForm');
            this.contryName = null;
            this.requForm.smpl_list = [];
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '委托单号重复'
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('requForm');
          this.contryName = null;
          this.requForm.smpl_list = [];
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 客户信息子传父值
    custPID(val) {
      if (val) {
        this.requForm.cust_pid = val;
      } else {
        this.requForm.cust_pid = null;
      }
    },
    custName(val) {
      if (val) {
        this.requForm.cust_name = val;
      } else {
        this.requForm.cust_name = null;
      }
    },
    custMail(val) {
      if (val) {
        this.requForm.cust_mail = val;
      } else {
        this.requForm.cust_mail = null;
      }
    },
    custFcpFlag(val) {
      if (val) {
        this.requForm.cust_fcp_flag = val;
      } else {
        this.requForm.cust_fcp_flag = 0;
      }
    },
    // 关闭弹框
    cancelCust() {
      this.custFlag = false;
    },
    custID(val) {
      if (val.cust_id) {
        this.requForm.cust_id = val.cust_id;
        this.requForm.cust_abbr = val.cust_abbr;
        this.requForm.cust_country = val.cust_country;
        this.contryName = val.cust_country;
      } else {
        this.requForm.cust_id = 0;
        this.requForm.cust_abbr = 0;
        this.requForm.cust_country = 0;
        this.contryName = 0;
      }
      if (val.cust_pid) {
        this.requForm.cust_pid = val.cust_pid;
      } else {
        this.requForm.cust_pid = val.cust_id;
      }
      if (val.cust_name) {
        this.requForm.cust_name = val.cust_name;
      } else {
        this.requForm.cust_name = null;
      }
      if (val.cust_mail) {
        this.requForm.cust_mail = val.cust_mail;
      } else {
        this.requForm.cust_mail = null;
      }
      if (val.cust_fcp_flag) {
        this.requForm.cust_fcp_flag = val.cust_fcp_flag;
      } else {
        this.requForm.cust_fcp_flag = 0;
      }
      this.custFlag = false;
    },
    custAbbr(val) {
      if (val) {
        this.requForm.cust_abbr = val;
      } else {
        this.requForm.cust_abbr = null;
      }
    },
    custCountry(val) {
      if (val) {
        this.requForm.cust_country = val;
        this.contryName = val;
      } else {
        this.requForm.cust_country = null;
      }
    },
    // 更换委托人获取英文名
    requStffChange() {
      for (let i = 0; i < this.stffType.length; i++) {
        if (this.requForm.requ_stff_name === this.stffType[i].stff_name) {
          this.requForm.requ_stff_ename = this.stffType[i].stff_ename;
        }
      }
    },
    // 选择客户名称变化国家
    // changeCustId(val){
    //   if(val){
    //     for(let i=0;i<this.custList.length;i++){
    //       if(this.custList[i].cust_name === val){
    //         this.contryName = this.custList[i].cust_country
    //       }
    //     }
    //   }else{
    //     this.contryName = null
    //   }
    // },
    //打开客户弹窗
    custImport() {
      if (!this.isShow) {
        this.custFlag = true;
      }
    },
    //关闭专属客户弹窗
    custCancel() {
      this.custFlag = false;
    },
    // 变更打样部门
    beloChange(val) {
      for (let i in this.requForm.smpl_list) {
        this.requForm.smpl_list[i].belo_dept_id = val;
      }
    },
    // 打开弹框
    smplImport() {
      this.smplFlag = true;
    },
    // 导入传值
    childSmpl(val) {
      let value = JSON.parse(JSON.stringify(val));
      for (let i = 0; i < value.length; i++) {
        let ids = JSON.parse(JSON.stringify(value[i].smpl_id));
        value[i].smpl_deil_time = null;
        value[i].smpl_type = 2;
        value[i].smpl_suffix = null;
        value[i].part_name = null;
        value[i].areTheyEqual = false;
        value[i].toys_type = 0;
        value[i].temp_smpl_id = ids;
        value[i].smpl_id = null;
        value[i].smpl_cust_num = 0;
        value[i].belo_dept_id = this.requForm.belo_dept_id;
        value[i].smpl_price = this.helper.haveFour(value[i].smpl_price);
        value[i].smpl_verify_price = this.helper.haveFour(value[i].smpl_verify_price);
      }
      this.requForm.smpl_list = this.requForm.smpl_list.concat(value);
      for (let i = 0; i < this.requForm.smpl_list.length; i++) {
        this.requForm.smpl_list[i].key = Number(i);
      }
      this.$refs.SmplEditIn.clear();
      this.smplFlag = false;
    },
    // 取消选择
    childSmplChanel() {
      this.smplFlag = false;
    },
    falseSmpl() {
      this.childSmplChanel();
    },
    // 样品list步骤新增
    addRow() {
      let item = {
        smpl_no: null,
        smpl_cust_no: null,
        smpl_name: null,
        smpl_stff_id: null,
        smpl_stff_name: null,
        part_name: null,
        smpl_suffix: null,
        toys_type: 0,
        smpl_type: 1,
        requ_id: 0,
        smpl_price: null,
        smpl_verify_price: null,
        smpl_num: null,
        belo_dept_id: this.requForm.belo_dept_id,
        smpl_deil_time: null,
        destroy_flag: 0,
        smpl_cust_num: 0,
        key: this.requForm.smpl_list.length
      };
      this.requForm.smpl_list.push(item);
      for (let i = 0; i < this.requForm.smpl_list.length; i++) {
        this.requForm.smpl_list[i].key = Number(i);
      }
    },
    // 样品list清单多选删除
    delBom() {
      if (this.selectChangeList.length > 0) {
        let temp = [];
        for (let i = 0; i < this.selectChangeList.length; i++) {
          let ind = null;
          ind = this.requForm.smpl_list.indexOf(this.selectChangeList[i]);
          temp.push(ind);
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.requForm.smpl_list.splice(j, 1);
        }
        for (let i = 0; i < this.requForm.smpl_list.length; i++) {
          this.requForm.smpl_list[i].key = Number(i);
        }
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    // 样品单list选择
    selectionChange(val) {
      this.selectChangeList = val;
    },
    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取打样部门
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let beloType = res.data.data.form.optn_cntt_list;
            for (let item of beloType) {
              item.param2 = Number(item.param2);
            }
            this.beloType = beloType;
          }
        })
        .catch(() => {});
    },
    // // 获取客户国家
    // getCustList(){
    //    get(custAPI.getAllCustsV1)
    //   .then(res=>{
    //     if(res.data.code === 0) {
    //       this.custList = res.data.data.list
    //     }else{
    //         let mg = res.data.msg;
    //         let tp = 'error';
    //         this.$message({message:mg,type:tp});
    //     }
    //   })
    //   .catch(res=>{
    //         let mg = res.data.msg;
    //         let tp = 'error';
    //         this.$message({message:mg,type:tp});
    //       })
    // },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_mrt1 {
  margin-top: 1px;
}
.vd_solidDiv {
  height: 1px;
  border: none;
  background-color: #dcdfe6;
  margin: 10px 0 20px 0;
}
.vd_but {
  margin-bottom: 10px;
}
</style>
