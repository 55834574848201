<template>
  <div class="vg_button_group">
    <el-button type="primary" @click="submit('cpttForm')" size="small">保存</el-button>
    <el-button @click="cancel" size="small">取消</el-button>
    <span class="vg_tag_position" v-if="stateFlag">
      <span class="vg_tag_label">状态：</span>
      <el-tag type="info">草拟</el-tag>
    </span>
  </div>
</template>

<script>
export default {
  name: 'addHeader',
  props: {
    strForm: Object,
    stateFlag: Boolean
  },
  methods: {
    submit() {
      this.$emit('submit');
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style scoped></style>
